/*
 * @Date: 2023-04-26 00:01:29
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:01:44
 * @FilePath: \F1-M3-QRP-CODE\config\trace\affDomains.js
 */
const affDomains = {
    // "localhost": "323667", //測試用
    // "fun88.biz" : "323665", // 测试使用
    "bestcacuoc888.com" : "323665",
};
export default affDomains;