import React from "react";

class Modal extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }


    close(){
        this.props.closeModal();
    }
    render(){
        let { isModal , error_text } = this.props;
        return (
            
            // 模态窗
            <>
                <div className="modal" style={{display:isModal ? 'block' : 'none'}}>
                    <div className="modal-box">
                        <div className="header">Đăng nhập thất bại</div>
                        <div className="center">{error_text}</div>
                        <div className="btn" onClick={()=>{this.close()}}>ok</div>
                    </div>
                    
                </div>
            </>

        )
    }

}

export default Modal;
