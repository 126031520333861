// 用户名格式正则
export const RegName = /^[a-zA-Z0-9]{6,16}$/;

export const RegNameNumber =/^\d+$/;

export const RegNameLetter = /^[a-zA-Z]+$/;

// 密码格式正则
export const RegPassword = /(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=[\^#$@]*)([a-zA-Z0-9]([\^#$@]*))+$/;
// 电话号码格式正则
export const RegPhone = /^[1-9]{1}[0-9]{8,9}$/;
// 电子邮箱格式正则
export const RegEmail = /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*/;