/*
 * @Date: 2023-03-15 18:06:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:52:39
 * @FilePath: \F1-M3-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 */

import { searchdomain } from "../default/otherDomain";
let mainDomain;
if (process.browser) {
    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
        mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
    }
}

const traceConfigs = {
    '': {
        piwik: '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga: false,
        "51la" : false,
        aff : false
    },
    'm3-001': {
        piwik: '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga: false,
        "51la" : false,
        aff : false

    },
    'm3-002' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false

    },
    'm3-003' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false

    },
    'm3-004' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false
    },
    'm3-005' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false

    },
    'm3-006' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false

    },
    'm3-007' : {
        piwik : false,
        ga : false,
        "51la" : false,
        aff : false
    },
    'm3-008' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false
    },
    'm3-009' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : searchdomain[mainDomain] && searchdomain[mainDomain].ga,
        "51la" : false,
        aff : false


    },
    'm3-010' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false

    },
    'm3-011' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        fb : true,
        ga : false,
        "51la" : false,
        aff : false

    },
    'm3-012' : {
        piwik : false,
        ga : false,
        "51la" : false,
        aff : false
    },
    'm3-013' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : false
    },
    'm3-014' : {
        piwik : '2a4d13c3-97b1-4c41-b681-c7969fc9db15',
        ga : 'UA-198324647-1',
        "51la" : false,
        aff : '341692'
    }
};






export default traceConfigs;
