/*
 * @Date: 2023-04-26 00:00:06
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:15:02
 * @FilePath: \F1-M3-QRP-CODE\config\default\otherDomain.js
 */
export const searchdomain =  {
    "localhost" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "bestcacuoc888.com": {
        piwik: false,
        "51la": false,
        ga: 'UA-198324647-1',
    },
    "funtructuyen88.com": {
        piwik: false,
        "51la": false,
        ga: 'UA-198324647-1',
    },
    "lucky678.com": {
        piwik: false,
        "51la": false,
        ga: 'UA-198324647-1',
    },
    "thugian88.com": {
        piwik: false,
        "51la": false,
        ga: 'UA-198324647-1',
    },
    "fundangky.com": {
        piwik: false,
        "51la": false,
        ga: 'UA-198324647-1',
    },
    "ngoaihang88.com": {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "126fun.com": {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "nufcworldcup.com": {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "hayhar88.com": {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "mathuong.com": {
        piwik: false,
        "51la": false,
        ga: false,
    },  
      
}