const initFB = () => {
  let LOC = window.location;
  let b1 = LOC.host.includes('vietclub.net');
  let b2 = LOC.pathname.includes('/m3-011/')
          && LOC.href.includes('mimichost=vietclub.net');
  if (b1 || b2) {
    if (b2) {
      console.log('loaded mimic fb');
    } else { // b1
      console.log('loaded fb');
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '686067303024363');
    fbq('track', 'PageView');
    }
  } else {
    console.log('no need fb');
  }
};

export default initFB;
