/*
 * @Date: 2023-03-16 10:53:35
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:32:47
 * @FilePath: \F1-M3-QRP-CODE\config\trace\index.js
 */
import traceConfigs from "./traceConfigs";
import initPiwik from "./items/piwik";
import init51la from "./items/la51";
import initFB from "./items/fb";
import initGA from "./items/ga";
import initSnare from "./items/snare";
import affDomains from "./affDomains";

const setupTraces = (pageName) => {
  if (process.browser) {
    const thisConfig = traceConfigs[pageName];
    if (thisConfig) {
      if (thisConfig.piwik) {
        initPiwik(thisConfig.piwik);
      }
      if (thisConfig['51la']) {
        init51la(thisConfig['51la']);
      }
      if (thisConfig.fb) {
        initFB(thisConfig.fb);
      }
      if (thisConfig.ga) {
        initGA(thisConfig.ga);
      }
      if (thisConfig.aff) {
        localStorage.setItem('aff_hardcode', thisConfig.aff);
      }
    }

    initSnare();

    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
      const mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
      const aff_hardcode = affDomains[mainDomain];
      if (aff_hardcode) {
        localStorage.setItem('aff_hardcode', aff_hardcode);
      }
    }
  }
}

export default setupTraces;
