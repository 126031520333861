import React from "react";


class Socialbtn extends React.Component{
	constructor(props){
		super(props);
		this.state = {

			qrcode:false,

			social_list : [
				{
					icon: '/static/images/hayhar/icon-customer.png',
					text: 'Live Chat',
					href: props.liveChatUrl || 'https://cranberryapp.hihi2u.com/app',
					gtag: ()=>{ global.globalGtag && globalGtag('Style1_QRP','Side nav bar','CS_sidenav' , 'event')}
				},{
					icon: '/static/images/hayhar/telegram.png',
					text: 'Telegram',
					href: 'https://t.me/Fun88CS',
					gtag: ()=>{ global.globalGtag && globalGtag('Style1_QRP','Side nav bar','Line_sidenav','event')}

				}
			]
		}
	}

	componentWillReceiveProps(nextProps , prevState){
		if(nextProps.liveChatUrl !== prevState.liveChatUrl){
			this.setState(state => {
				const list = state.social_list.map((item, index) => {
					if (item.text === 'Live Chat') {
						item.href = nextProps.liveChatUrl
					}
				})
				return {
				  list
				};
			});
        }
        return;
    }

	gtagPiwik(fn){
		fn();
	}

	onMouseEnter(key){
		if(key == 1){
			this.setState({
				qrcode: true,
			})
		}else{
			this.setState({
				qrcode:false
			})
		}
	}

	onMouseLeave(key){
		this.setState({
			qrcode:false,
		})
	}
	render(){
		let { social_list ,qrcode } = this.state;
		return (
			<>
				{/* social btns */}				
				<div className={'social-web'}>
					{
						social_list.map((item , key) =>{
							if (item.text === 'Live Chat') {
								return (
									<a target='_blank' onClick={()=> {global.globalGtag && global.globalGtag('Style1_QRP','Side nav bar' , 'CS_sidenav' ,'event'); window.open(item.href, "Live Chat", "width=600,height=600")}} onMouseLeave={()=>{this.onMouseLeave(key)}} onMouseEnter={()=>{this.onMouseEnter(key)}}  key={key} className={'social-item'}>
										<div className="social-item-div" style={{display:'flex',alignItems:'center'}}>
											<img src={item.icon} />
											<span>{item.text}</span>
										</div>
									</a>
								)
							} else {
								return (
									<a target='_blank' onClick={()=>{this.gtagPiwik(item.gtag)}} onMouseLeave={()=>{this.onMouseLeave(key)}} onMouseEnter={()=>{this.onMouseEnter(key)}}  key={key} href={item.href} className={'social-item'}>
										<div className="social-item-div" style={{display:'flex',alignItems:'center'}}>
											<img src={item.icon} />
											<span>{item.text}</span>
										</div>
									</a>
								)
							}
								
						})
					}
				</div>
			</>
		)
	}
}

export default Socialbtn;