/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:43:33
 * @FilePath: \F1-M3-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;


export const ApiPort = {
	Banner1 : CMSURL + `/cms/qrp-m3-001-banners`,
	Banner2 : CMSURL + `/cms/qrp-m3-002-banners`,
	Banner3 : CMSURL + `/cms/qrp-m3-003-banners`,
	Banner4 : CMSURL + `/cms/qrp-m3-004-banners`,
	Banner5 : CMSURL + `/cms/qrp-m3-005-banners`,
	Banner6 : CMSURL + `/cms/qrp-m3-006-banners`,
	Banner7 : CMSURL + `/cms/qrp-m3-007-banners`,
	Banner8 : CMSURL + `/cms/qrp-m3-008-banners`,
	Banner9 : CMSURL + `/cms/qrp-m3-009-banners`,
	Banner10 : CMSURL + `/cms/qrp-m3-010-banners`,
	Banner11 : CMSURL + `/cms/qrp-m3-011-banners`,
	Banner12 : CMSURL + `/cms/qrp-m3-012-banners`,
	Banner13 : CMSURL + `/cms/qrp-m3-013-banners`,
	Banner14 : CMSURL + `/cms/qrp-m3-014-banners`,





	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	// 
	QrpLoad : HostApi + '/api/QRP/URLs' ,
}