/*
 * @Date: 2023-02-07 11:33:31
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 00:55:54
 * @FilePath: \F1-M3-QRP-CODE\components\hayhar\header.js
 */
import React from "react";
import { Input , Button } from 'antd';
import { Cookie  , _handleTheMainSiteUrlOfBackendResponse, getApiVersion } from "../../actions/util";
import { fetchRequest } from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";
import Toast from '@/Toast';

class Header extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			username : '',
			password : '',
			mobileLogin: false
		}
		this.login = this.login.bind(this);
		this.loginMobile = this.loginMobile.bind(this);
		this.mobileLoginForget = this.mobileLoginForget.bind(this)
	}

	login(){
		let { password , username } = this.state;
		console.log(123123);
		if(!password && !username) return ;
		let params = {
			currentDomain : global.location.origin || '',
			hidE2:  (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '',
			language : 'vi-vn',
			password : password , 
			username : username
		}
		global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav' ,'event');
		Toast.loading()
		fetchRequest(ApiPort.Login , 'POST' , params , getApiVersion()).then(res =>{
			try {
				Toast.hide();
				if(res){
					if(res.isSuccess){
						global.globalCTag && global.globalCTag('api_return_complete','success'); 
						global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav_api_return' ,'event', 'custom');
						_handleTheMainSiteUrlOfBackendResponse(`${res.url}&token=${res.token}`);
					}else{
						global.globalCTag && global.globalCTag('api_return_complete','fail'); 
						global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav_api_return' ,'event', 'custom');
						this.props.openModal(res.message);
						// Toast.fail(res.message);
					}
				}
			} catch (error) {
				console.log('====================================');
				console.log('服务器错误');
				console.log('====================================');
			}
		})
	}
	
	loginMobile(){
		this.setState({
			mobileLogin: true
		})
		// global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav' ,'event');

	}

	mobileLoginForget() {
		let { mainSiteUrl } = this.props;
		let aff = global.document && Cookie.GetCookieKeyValue('CO_affiliate') || '';
		global.globalGtag && global.globalGtag('Style1_QRP','Forget Password','ForgetPW_topnav','event')
		window.location.replace(mainSiteUrl + `/member/forgotpassword.htm${aff ? `?aff=${aff}` : ''}`, '_blank', 'noopener,noreferrer');

	}

	join(){
		global.globalGtag && global.globalGtag('Style1_QRP','Registration form','Register_topnav','event');
		const dom = document.getElementById('mobile-heading');
		dom.scrollIntoView({behavior : 'smooth'});
	}


	render(){
		let { username , password} = this.state;
		let { mainSiteUrl } = this.props;
		let aff = global.document && Cookie.GetCookieKeyValue('CO_affiliate') || '';
		return (
			<>

				<div className={'top web'}>
					<img data-aff={aff} onClick={()=>{global.location.href = mainSiteUrl + '?=aff' + aff}} src="/static/images/hayhar/fun88-logo.png" className={'qrp-header-logo'} />
					
					<div className={'web-input-box'}>
						{/* 用户名 */}
						<Input maxLength={20} name="username" type={'text'} placeholder="Tên người dùng" className={'web-input'} value={username} onChange={(e)=>{this.setState({username : e.target.value})}}></Input>
						{/* 密码 */}
						<Input className={'web-input'} name="password" type={'password'} placeholder="Mật khẩu người dùng" value={password} onChange={(e)=>{this.setState({password : e.target.value})}}></Input>
						{/* 忘记密码了吗 */}
						<a onClick={()=>{global.globalGtag && global.globalGtag('Style1_QRP','Forget Password','ForgetPW_topnav','event')}} href={`${mainSiteUrl}${aff ? '?aff='+aff : ''}?openForgotPassword=1`} className={'web-label'}>Quên?</a>
						{/* 登录 */}
						<Button className={'web-login'} onClick={this.login}>Đăng nhập</Button>
						<img src="/static/images/hayhar/flag-thb.png" className={'country-icon'} />
					</div>
				</div>

				
				
				{/* mobile and ipad */}
				<div className="top mobile">
					<img src="/static/images/hayhar/fun88-logo.png" className={'qrp-header-logo'} data-aff={aff} onClick={()=>{global.location.href = mainSiteUrl + '?aff=' + aff}}/>
					<div className={'web-input-box'}>
						{/* 登录 */}
						<Button className="login" onClick={()=>{this.loginMobile()}}>Đăng nhập</Button>
						{/* 现在加入 */}
						{/* <Button className="register" onClick={this.join}>Tham gia ngay</Button> */}
					</div>
				</div>
				{this.state.mobileLogin &&
					<div className="modal" style={{display:'block'}}>
						<div className="containerMobileLogin">
						<div className="mobileLoginModal">
							<div className="header">
								<div>
									Đăng nhập nhanh
								</div>
								<div onClick={()=> {this.setState({mobileLogin: false})}} style={{color: '#d4d8dd', fontWeight: 'normal'}}>
									&#10006;
								</div> 
							</div>
							<div className="body">
								<div>
									<Input maxLength={20} name="username" type={'text'} placeholder="Tên người dùng" className={'web-input'} value={username} onChange={(e)=>{this.setState({username : e.target.value})}}></Input>

								</div>
								<div className="forgetPw">
									<Input addonAfter={<span onClick={()=> this.mobileLoginForget() }>Quên?</span>} className={'web-input'} name="password" type={'password'} placeholder="Mật khẩu người dùng" value={password} onChange={(e)=>{this.setState({password : e.target.value})}}></Input>

								</div>
							</div>
							<div className="btn" onClick={this.login}>Đăng nhập</div>
						</div>
						</div>
						
					</div>
				}

			</>
		)
	}
}


export default Header;
